var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"file-item",style:({ height: `${_vm.size}px`, width: `${_vm.size}px` })},[(
      _vm.type == 'image' &&
      ['jpg', 'png', 'jpeg', 'gift', ''].indexOf(_vm.item.ext) > -1
    )?_c('el-image',{staticClass:"image",attrs:{"fit":"contain","src":_vm.item.uri}}):_vm._e(),(
      _vm.type == 'image' &&
      ['jpg', 'png', 'jpeg', 'gift', ''].indexOf(_vm.item.ext) == -1
    )?_c('el-image',{staticClass:"image",attrs:{"fit":"contain","src":_vm.fileIcon}}):(_vm.type == 'video')?_c('video',{staticClass:"video",attrs:{"src":_vm.item.uri}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }